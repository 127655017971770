import { recaptchaKey } from "configs";
import useRecaptchaV3 from "hooks/useRecaptchaV3";
import { AlertCircle, Edit } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormGroup,
  Input,
  InputGroupText,
  Label,
  Spinner,
} from "reactstrap";
import ModalConfirmation from "./ModalConfirmation";
import { sendOtp } from "./reasonAction";
import { setReason, toggleModal } from "./reasonSlice";
import { useState } from "react";

const Reason = () => {
  const dispatch = useDispatch();
  const recaptcha = useRecaptchaV3(recaptchaKey);
  const { reason, loading } = useSelector((s) => s.reason);
  const { phoneNumber } = useSelector((s) => s.authDeletion);
  const [errMsg, setErrMsg] = useState("");

  const handleSubmit = async () => {
    try {
      setErrMsg("");
      let keyRecaptha = await recaptcha();
      const res = await dispatch(
        sendOtp({ phoneNumber, recaptcha: keyRecaptha })
      );
      if (res?.error) {
        setErrMsg(res?.payload);
      } else {
        dispatch(toggleModal());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalConfirmation />
      <div className="d-flex flex-column">
        <FormGroup>
          <Label className="fw-semibold">
            Tuliskan alasan mengapa kamu menghapus akun? (opsional)
          </Label>
          <InputGroupText
            size="lg"
            className="bg-white d-flex align-items-start"
          >
            <div className="mt-1">
              <Edit size={19} />
            </div>
            <Input
              style={{ width: "100%", height: "209px" }}
              placeholder="Berikan alasan"
              type="textarea"
              className="border-0"
              value={reason}
              onChange={(e) => dispatch(setReason(e.target.value))}
            />
          </InputGroupText>
          <p>Kamu bisa mengosongkan kolom ini dan melanjutkan proses</p>
        </FormGroup>
        {errMsg && (
          <FormGroup>
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </FormGroup>
        )}
        <div>
          <Button
            disabled={loading}
            onClick={() => handleSubmit()}
            block
            color="primary"
          >
            {loading ? <Spinner size="sm" /> : "Lanjut"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Reason;
