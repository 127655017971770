import { Outlet, useNavigate } from "react-router-dom";
import NavbarDashboard from "./navbar";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const FarmersWeb = () => {
  const navigate = useNavigate();
  const { dataUser } = useSelector((s) => s.auth);

  useEffect(() => {
    if (!dataUser?.api_token) {
      navigate("/farmers/login");
    }
  }, [dataUser]);

  return (
    <div className="bg-light" style={{ minHeight: "100vh" }}>
      <NavbarDashboard
        container="md"
        color="white"
        expand="md"
        style={{ padding: "32px 0px 32px 0px" }}
      />
      <Outlet />
    </div>
  );
};

export default FarmersWeb;
