import { createAppSlice } from "app/createAppSlice";
import { checkUser, loginUserDeletion } from "./loginAction";

export const initialState = {
  loading: false,
  phoneNumber: "",
  error: "",
  token: "",
  requirements: "",
  recaptcha: "",
};

export const loginSliceDeletion = createAppSlice({
  name: "authDeletion",
  initialState,
  reducers: (create) => ({
    setPhoneNumber: create.reducer((state, { payload }) => {
      state.phoneNumber = payload;
    }),
    setRequirements: create.reducer((state, { payload }) => {
      state.requirements = payload;
    }),
    clearError: create.reducer((state, { payload }) => {
      state.error = payload;
    }),
    setRecaptcha: create.reducer((state, { payload }) => {
      state.recaptcha = payload;
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(loginUserDeletion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUserDeletion.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(loginUserDeletion.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(checkUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(checkUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});
export const { setPhoneNumber, clearError, setRequirements, setRecaptcha } =
  loginSliceDeletion.actions;
export default loginSliceDeletion.reducer;
