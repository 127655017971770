import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiEndpoint } from "configs";
import {
  API_DELETION_CHECK_USER,
  API_DELETION_LOGIN_REQUIREMENTS,
} from "utils/apiList";

const loginUserDeletion = createAsyncThunk(
  "auth/login",
  async ({ phoneNumber, password, recaptcha }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${apiEndpoint}/${API_DELETION_LOGIN_REQUIREMENTS}`,
        { phoneNumber, password, "g-recaptcha-response": recaptcha },
        config
      );

      return data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const checkUser = createAsyncThunk(
  "auth/check-user",
  async ({ phoneNumber, recaptcha }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${apiEndpoint}/${API_DELETION_CHECK_USER}`,
        { phoneNumber, "g-recaptcha-response": recaptcha },
        config
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export { loginUserDeletion, checkUser };
