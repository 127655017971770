import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig, apiEndpoint } from "configs";
import { API_FARMERS_LOGIN } from "utils/apiList";

const loginUser = createAsyncThunk(
  "auth/login",
  async ({ phoneNumber, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94",
        },
      };
      const { data } = await axios.post(
        `${apiEndpoint}/${API_FARMERS_LOGIN}`,
        { identity: phoneNumber, password },
        config
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.meta.message) {
        return rejectWithValue(error.response.data.meta.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export { loginUser };
