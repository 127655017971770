import React, { useEffect, useState } from "react";
import { ArrowDown, ArrowRight, CheckCircle } from "react-feather";
import { useIntl } from "react-intl";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Spinner,
} from "reactstrap";
import {
  useFetchFarmersDetailQuery,
  useFetchFarmersPlotQuery,
} from "../dashboard/dashboardApiSlice";
import ModalLandPlot from "./ModalLandPlot";
import ModalChangeProfil from "./ModalChangeProfil";
import { toggleBannerDetail, toggleModalChangeProfile } from "./detailSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFarmersDetail } from "../dashboard/dashboardAction";
import axios from "axios";

const DetailUser = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userId = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const { data } = useFetchFarmersDetailQuery({ id: userId });
  const resp = useFetchFarmersPlotQuery({ id: userId });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState({});
  const [isClick, setIsClick] = useState(false);
  const [imgSelfieSrc, setImgSelfieSrc] = useState("");
  const [imgKtpSrc, setImgKtpSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const { isOpenBanner } = useSelector((s) => s.detail);
  const { dataUser } = useSelector((s) => s.auth);

  const regions = isClick
    ? resp?.data?.data?.items
    : !isClick && resp?.data?.data?.items.length > 3
      ? resp?.data?.data?.items.slice(0, -(resp?.data?.data?.items.length - 3))
      : resp?.data?.data?.items;

  const getSrc = (link, type) => {
    setIsLoading(true);
    const imageUrl = link;
    const bearerToken = dataUser?.api_token;

    axios
      .get(imageUrl, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const imgUrl = URL.createObjectURL(response.data);
        if (type === "img-ktp") {
          setImgKtpSrc(imgUrl);
        } else {
          setImgSelfieSrc(imgUrl);
        }
      })
      .catch((error) => {
        console.error("There was a problem fetching the image:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (userId) {
      dispatch(getFarmersDetail());
    }

    if (data?.data?.imgSelfie) {
      getSrc(data?.data?.imgSelfie, "img-selfie");
    }
    if (data?.data?.imgKTP) {
      getSrc(data?.data?.imgKTP, "img-ktp");
    }
  }, [userId, data]);

  return (
    <Container className="custom-container d-flex flex-column gap-3 py-3">
      <ModalLandPlot
        row={selectedPlot}
        isOpen={isModalOpen}
        toggle={toggleModal}
      />
      <ModalChangeProfil row={data?.data} />

      <div>
        <Alert
          isOpen={isOpenBanner}
          toggle={() => dispatch(toggleBannerDetail())}
          color="success"
          className="d-flex align-items-center my-4 w-100"
        >
          <span>
            <CheckCircle size={17} /> Data diri berhasil diubah.
          </span>
        </Alert>
      </div>

      {/* Data Akun */}
      <Card>
        <CardBody className="d-flex flex-column gap-2">
          <h5 className="fw-semibold">Data Akun</h5>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span className="text-primary fw-semibold">
                {data?.data?.fullName}
              </span>
              <p>{data?.data?.phoneNumber}</p>
            </div>
            <Badge
              className={
                data?.data?.status === "VERIFIED"
                  ? "badge-success"
                  : "badge-danger"
              }
            >
              {data?.data?.status === "VERIFIED"
                ? "Terverifikasi"
                : "Belum Terverifikasi"}
            </Badge>
          </div>
        </CardBody>
      </Card>

      {/* Data Foto */}
      <Card>
        <CardBody className="d-flex flex-column gap-2">
          <h5 className="fw-semibold">Identitas</h5>
          <div className="d-flex gap-2">
            <div>
              <p>Foto KTP</p>
              {(isLoading && !imgKtpSrc) || !data ? (
                <Spinner />
              ) : (
                <img
                  style={{ width: "180px", height: "120px" }}
                  id="img-ktp"
                  className="img-fluid rounded"
                  src={imgKtpSrc}
                  alt="Foto Ktp"
                />
              )}
            </div>
            <div>
              <p>Foto Selfie</p>
              {(isLoading && !imgSelfieSrc) || !data ? (
                <Spinner />
              ) : (
                <img
                  style={{ width: "180px", height: "120px" }}
                  id="img-selfie"
                  className="img-fluid rounded"
                  src={imgSelfieSrc}
                  alt="Foto Selfie"
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Data Diri */}
      <Card>
        <CardBody className="d-flex flex-column gap-2">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Data Diri</h5>
            <h6
              onClick={() => dispatch(toggleModalChangeProfile())}
              style={{ cursor: "pointer" }}
              className="text-primary"
            >
              Ubah
            </h6>
          </div>
          <div>
            <span className="fw-semibold">Nama</span>
            <p className="text-muted">{data?.data?.fullName}</p>
          </div>
          <div>
            <span className="fw-semibold">Tanggal lahir</span>
            <p className="text-muted">{data?.data?.dob}</p>
          </div>
          <div>
            <span className="fw-semibold">Jenis Kelamin</span>
            <p className="text-muted">
              {data?.data?.sex == 0 ? "Perempuan" : "Laki-laki"}
            </p>
          </div>
          <div>
            <span className="fw-semibold">Email </span>
            <p className="text-muted">{data?.data?.email}</p>
          </div>
          <div>
            <span className="fw-semibold">NIK</span>
            <p className="text-muted">{data?.data?.noKTP}</p>
          </div>
          <div>
            <span className="fw-semibold">Provinsi</span>
            <p className="text-muted">{data?.data?.province}</p>
          </div>
          <div>
            <span className="fw-semibold">Kota</span>
            <p className="text-muted">{data?.data?.city}</p>
          </div>
          <div>
            <span className="fw-semibold">Kecamatan</span>
            <p className="text-muted">{data?.data?.district}</p>
          </div>
          <div>
            <span className="fw-semibold">Kelurahan</span>
            <p className="text-muted">{data?.data?.subDistrict}</p>
          </div>
          <div>
            <span className="fw-semibold">Kode pos</span>
            <p className="text-muted">{data?.data?.postalCode}</p>
          </div>
          <div>
            <span className="fw-semibold">Alamat</span>
            <p className="text-muted">{data?.data?.address}</p>
          </div>
        </CardBody>
      </Card>

      {/* Data Bank */}
      <Card>
        <CardBody className="d-flex flex-column gap-2">
          <h5>Rekening Bank</h5>

          <div>
            <span className="fw-semibold">Nomor rekening</span>
            <p className="text-muted">{data?.data?.bankAccountNumber}</p>
          </div>
          <div>
            <span className="fw-semibold">Bank</span>
            <p className="text-muted">{data?.data?.bankName}</p>
          </div>
          <div>
            <span className="fw-semibold">Nama pemilik rekening</span>
            <p className="text-muted">{data?.data?.bankAccountName}</p>
          </div>
        </CardBody>
      </Card>

      {/* Data Wilayah */}
      <Card>
        <CardBody className="d-flex flex-column gap-2">
          <h5>Wilayah Komoditas</h5>

          {resp?.data?.data?.items.length < 1 ? (
            <span className="text-muted">Belum ada wilayah yang direkam</span>
          ) : (
            regions?.map((e, i) => {
              return (
                <div
                  key={i}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <span className="text-primary fw-semibold">
                      {e?.landName}
                    </span>
                    <p className="text-muted">
                      Perkiraan luas:{" "}
                      {`${intl.formatNumber(e?.size / 10000, {
                        useGrouping: "always",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })} Ha`}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedPlot(e);
                      toggleModal();
                    }}
                    className="fw-semibold"
                    style={{ cursor: "pointer" }}
                  >
                    Lihat Wilayah <ArrowRight />
                  </div>
                </div>
              );
            })
          )}
          {resp?.data?.data?.items.length > 3 && !isClick ? (
            <Button
              onClick={() => setIsClick(true)}
              block
              outline
              color="primary"
            >
              <ArrowDown /> Muat Lebih Banyak
            </Button>
          ) : null}
        </CardBody>
      </Card>
    </Container>
  );
};

export default DetailUser;
