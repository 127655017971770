import { persistor } from "app/store";
import LogoPasmik from "assets/images/pmg-logo.png";
import { recaptchaKey } from "configs";
import useRecaptchaV3 from "hooks/useRecaptchaV3";
import { useEffect } from "react";
import { AlertCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Form, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { checkUser } from "./loginAction";
import { clearError, setPhoneNumber } from "./loginSlice";

const LoginDeletion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptcha = useRecaptchaV3(recaptchaKey);
  const { phoneNumber, error } = useSelector((s) => s.authDeletion);

  const handleSubmit = async () => {
    try {
      let keyRecaptha = await recaptcha();
      const res = await dispatch(
        checkUser({ phoneNumber, recaptcha: keyRecaptha })
      );
      if (res?.error) {
        throw res;
      } else {
        navigate("/penghapusan/pin", { replace: true });
      }
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(clearError());
    // window.location.reload();
    persistor.purge();
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Card style={{ maxWidth: "600px", width: "100%" }}>
          <CardBody>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                style={{ width: "100%", maxWidth: "177px", height: "auto" }}
                src={LogoPasmik}
                alt="PasarMIKRO logo"
              />
            </div>
            <CardTitle className="d-flex justify-content-center mb-4" tag="h4">
              Silakan masuk ke akun anda
            </CardTitle>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Nomor Telepon</Label>
                <Input
                  autoFocus={true}
                  onChange={(e) => {
                    dispatch(clearError());
                    dispatch(
                      setPhoneNumber(e.target.value.replace(/[^0-9]+/g, ""))
                    );
                  }}
                  value={phoneNumber}
                  placeholder="Contoh: 081991334632"
                  autoComplete="off"
                />
              </FormGroup>
              {error && (
                <FormGroup>
                  <small className="text-danger">
                    <AlertCircle size={15} /> {error}
                  </small>
                </FormGroup>
              )}

              <Button
                disabled={!phoneNumber || phoneNumber.length < 10}
                block
                color="primary"
                type="submit"
              >
                Lanjutkan
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default LoginDeletion;
