import { landplotLink } from "configs";
import { Modal, ModalBody } from "reactstrap";

const ModalLandPlot = ({ row, isOpen, toggle }) => {
  return (
    <Modal centered size="lg" toggle={toggle} isOpen={isOpen}>
      <ModalBody>
        <h6>{row?.landName}</h6>
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <iframe
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            className="rounded"
            src={`${landplotLink}?code=${row?.code}`}
            title="Land Plot"
            style={{
              width: "100%",
              height: "60vh",
              border: "none",
            }}
          ></iframe>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalLandPlot;
