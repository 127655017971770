import { persistor } from "app/store";
import iconMail from "assets/icons/mail-icon.png";
import { recaptchaKey } from "configs";
import useRecaptchaV3 from "hooks/useRecaptchaV3";
import { useState } from "react";
import { AlertCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormGroup, Input, Modal, ModalBody } from "reactstrap";
import { sendOtp, sendVerifyOtp } from "./reasonAction";
import { clearError, toggleModal } from "./reasonSlice";

const ModalConfirmation = () => {
  const dispatch = useDispatch();
  const recaptcha = useRecaptchaV3(recaptchaKey);
  const { isOpenModal, error } = useSelector((s) => s.reason);
  const { phoneNumber } = useSelector((s) => s.authDeletion);
  const { reason } = useSelector((s) => s.reason);
  const [pin, setPin] = useState([]);
  const [active, setActive] = useState("form-control-0");
  const [inAction, setInAction] = useState(false);

  const hanldeSendAgain = async () => {
    try {
      setInAction(true);
      let keyRecaptha = await recaptcha();
      const res = await dispatch(
        sendOtp({ recaptcha: keyRecaptha, phoneNumber })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setInAction(false);
    }
  };

  const handleSubmit = async () => {
    try {
      let keyRecaptha = await recaptcha();
      const res = await dispatch(
        sendVerifyOtp({
          phoneNumber,
          notes: reason,
          otp: pin.join(""),
          recaptcha: keyRecaptha,
        })
      );
      if (res?.payload?.executed === 1) {
        persistor.purge();
        window.location.href = "/penghapusan";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nextInput = (id) => {
    console.log("masuk", id);
    const input = document.getElementById(id);
    if (input) input.focus();
  };

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpenModal}
      toggle={() => dispatch(toggleModal())}
    >
      <ModalBody>
        <div className="d-flex flex-column align-items-center">
          <img
            className="img-fluid"
            style={{ width: "48px", height: "48px" }}
            src={iconMail}
            alt="Mail Icon"
          />
          <h5 className="mt-3">Silakan cek WhatsApp anda</h5>
          <p className="text-muted">
            Kami mengirimkan kode ke nomor {phoneNumber}
          </p>
        </div>
        <div>
          <FormGroup className="d-flex gap-2">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="position-relative">
                <div
                  className="position-absolute top-0 start-0 w-100 h-100"
                  style={{ zIndex: 2, backgroundColor: "transparent" }}
                  onClick={() => nextInput(active)}
                ></div>
                <Input
                  style={{ zIndex: 1 }}
                  autoFocus={i === 0}
                  className="w-100 h-100 fs-2 border border-primary text-center text-primary"
                  id={`form-control-${i}`}
                  key={i}
                  maxLength={1}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={(e) => {
                    dispatch(clearError());
                    const value = e.target.value;
                    if (!e.code.includes("Digit") && e.code !== "Backspace") {
                      e.preventDefault();
                    } else if (value.match(/^[0-9]$/)) {
                      if (e.code.includes("Digit")) {
                        setActive(`form-control-${i + 1}`);
                        nextInput(`form-control-${i + 1}`);
                      }
                      setPin((prev) => {
                        const newPin = [...prev];
                        newPin[i] = value;
                        return newPin;
                      });
                    } else {
                      if (e.code === "Backspace") {
                        setActive(`form-control-${i - 1}`);
                        nextInput(`form-control-${i - 1}`);
                        setPin((prevPin) => {
                          return prevPin.filter((_, index) => index !== i);
                        });
                      } else {
                      }
                    }
                  }}
                  onChange={(e) => {
                    dispatch(clearError());
                    const value = e.target.value;
                    if (value.match(/^[0-9]$/)) {
                      setPin((prev) => {
                        const newPin = [...prev];
                        newPin[i] = value;
                        return newPin;
                      });
                      if (e.target.id !== "form-control-4") {
                        setActive(`form-control-${i + 1}`);
                        nextInput(`form-control-${i + 1}`);
                      }
                    } else {
                      if (e.target.id !== "form-control-0") {
                        setActive(`form-control-${i - 1}`);
                        nextInput(`form-control-${i - 1}`);
                        setPin((prevPin) => {
                          return prevPin.filter((_, index) => index !== i);
                        });
                      } else {
                        return null;
                      }
                    }
                  }}
                  autoComplete="off"
                />
              </div>
            ))}
          </FormGroup>
          <p className="text-center py-2 text-muted">
            Tidak menerima kode?{" "}
            <a
              onClick={hanldeSendAgain}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Kirim ulang kode
            </a>
          </p>
          {error && (
            <FormGroup>
              <small className="text-danger">
                <AlertCircle size={15} /> {error}
              </small>
            </FormGroup>
          )}
        </div>

        <div className="d-flex gap-3">
          <Button
            disabled={inAction}
            onClick={() => dispatch(toggleModal())}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={inAction}
            onClick={() => handleSubmit()}
            color="oranges"
            block
          >
            Hapus Akun
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalConfirmation;
