import LogoPasmik from "assets/images/pmg-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Form, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { setPhoneNumber } from "./loginSlice";
import { useEffect } from "react";
import { persistor } from "app/store";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { phoneNumber, loading } = useSelector((s) => s.auth);
  const handleSubmit = async (event) => {
    event.preventDefault();
    navigate("/farmers/pin");
  };

  useEffect(() => {
    persistor.purge();
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Card style={{ maxWidth: "600px", width: "100%" }}>
          <CardBody>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                style={{ width: "100%", maxWidth: "177px", height: "auto" }}
                src={LogoPasmik}
                alt="PasarMIKRO logo"
              />
            </div>
            <CardTitle className="d-flex justify-content-center mb-4" tag="h4">
              Silakan masuk ke akun anda
            </CardTitle>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Nomor Telepon</Label>
                <Input
                  autoFocus
                  onChange={(e) => {
                    dispatch(
                      setPhoneNumber(e.target.value.replace(/[^0-9]+/g, ""))
                    );
                  }}
                  value={phoneNumber}
                  placeholder="Contoh: 081991334632"
                  autoComplete="off"
                />
              </FormGroup>
              {/* {isError && (
                <FormGroup>
                  <small className="text-danger">
                    <AlertCircle size={15} /> {error}
                  </small>
                </FormGroup>
              )} */}

              <Button
                disabled={!phoneNumber || phoneNumber.length < 10 || loading}
                block
                color="primary"
                type="submit"
              >
                Lanjutkan
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default Login;
