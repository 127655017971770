import { persistor } from "app/store";
import { LogOut, User } from "react-feather";
import { useSelector } from "react-redux";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
} from "reactstrap";

const UserDropdown = () => {
  const { dataUser } = useSelector((s) => s.auth);

  return (
    <Nav className="nav navbar-nav align-items-center ml-auto">
      <UncontrolledDropdown tag="li" className="me-2" direction="down">
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link dropdown-user-link d-flex justify-content-center align-items-center gap-2"
          onClick={(e) => e.preventDefault()}
        >
          <div
            className="rounded-circle d-flex justify-content-center align-items-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#ECFAFF",
            }}
          >
            <span>
              <User size={20} className="text-primary" />
            </span>
          </div>
          <span className="text-muted">{dataUser?.name}</span>
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem
            tag="a"
            href="/farmers/login"
            onClick={() => {
              persistor.purge();
            }}
          >
            <LogOut size={15} className="me-2" />
            <span className="align-middle">Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

export default UserDropdown;
