//farmers
export const API_FARMERS_LOGIN = "api/v1/page/login-group";
export const API_FARMERS_REGISTER = "api/v1/group/register-farmer";
export const API_FARMERS_LIST = "api/v1/group/register-farmer";
export const API_FARMERS_PRECHECK = "api/v1/group/precheck-farmer";
export const API_FARMERS_UPLOAD_FILE =
  "api/v1/group/upload-private-file-farmer";
export const API_FARMERS_OCR = "api/v1/group/ocr-ktp-farmer";
export const API_FARMERS_PLOTTING = "api/v1/group/landplot";
export const API_FARMERS_BANK_LIST = "api/v1/group/banks";
export const API_FARMERS_PROVINCE_LIST = "api/v1/public/wilayah/provinsi";
export const API_FARMERS_CITY_LIST = "api/v1/public/wilayah/kabkota";
export const API_FARMERS_DISTRICT_LIST = "api/v1/public/wilayah/kecamatan";
export const API_FARMERS_SUBDISTRICT_LIST = "api/v1/public/wilayah/kelurahan";
export const API_FARMERS_POSTALCODE_LIST = "api/v1/public/wilayah/kodepos";

//deletion
export const API_DELETION_LOGIN_REQUIREMENTS =
  "api/v1/public/userdelete/requirements";
export const API_DELETION_SEND_OTP = "api/v1/public/userdelete/sendotp";
export const API_DELETION_VERIFY_OTP = "api/v1/public/userdelete/verify";
export const API_DELETION_CHECK_USER = "api/v1/public/userdelete/checkuser";
