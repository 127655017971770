import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// Persist
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import loginSliceDeletion from "views/deletion/login/loginSlice";
import reasonSlice from "views/deletion/reason/reasonSlice";
import { dashboardApiSlice } from "views/farmers-web/dashboard/dashboardApiSlice";
import dashboardSlice from "views/farmers-web/dashboard/dashboardSlice";
import detailSlice from "views/farmers-web/detail/detailSlice";
import { formApiSlice } from "views/farmers-web/forms/formApiSlice";
import formSlice from "views/farmers-web/forms/formSlice";
import loginSlice from "views/farmers-web/login/loginSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "dashboard"],
};

const rootReducer = combineReducers({
  auth: loginSlice,
  authDeletion: loginSliceDeletion,
  dashboard: dashboardSlice,
  reason: reasonSlice,
  form: formSlice,
  detail: detailSlice,
  [dashboardApiSlice.reducerPath]: dashboardApiSlice.reducer,
  [formApiSlice.reducerPath]: formApiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = (preloadedState) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(dashboardApiSlice.middleware, formApiSlice.middleware);
    },
    preloadedState,
  });
  setupListeners(store.dispatch);
  return store;
};

export const store = makeStore();
export let persistor = persistStore(store);
