import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiEndpoint } from "configs";
import { API_DELETION_SEND_OTP, API_DELETION_VERIFY_OTP } from "utils/apiList";

const sendOtp = createAsyncThunk(
  "reason/sendotp",
  async ({ phoneNumber, recaptcha }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${apiEndpoint}/${API_DELETION_SEND_OTP}`,
        { phoneNumber, "g-recaptcha-response": recaptcha },
        config
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const sendVerifyOtp = createAsyncThunk(
  "reason/verifyotp",
  async ({ phoneNumber, otp, notes, recaptcha }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${apiEndpoint}/${API_DELETION_VERIFY_OTP}`,
        { phoneNumber, otp, notes, "g-recaptcha-response": recaptcha },
        config
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.meta.message) {
        return rejectWithValue(error.response.data.meta.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export { sendOtp, sendVerifyOtp };
