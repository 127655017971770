import { useRef, useState } from "react";
import { Alert, Card } from "reactstrap";

// components
import ICUpload from "assets/icons/ic-upload.svg";
import ICXAlert from "assets/icons/ic-x-alert.svg";

import { useDispatch, useSelector } from "react-redux";
import { useOcrKtpMutation, useUploadImageMutation } from "./formApiSlice";
import { setPayloadOcr, setUrlKtp, setUrlSelfie } from "./formSlice";
import { uploadImage } from "./formsAction";

const CardUploadImage = ({ type, ...args }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [dataImg, setDataImg] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { dataUser } = useSelector((s) => s.auth);

  const [visibleErrorMsg, setVisibleErrorMsg] = useState(false);
  const [visiblePreviewImg, setVisiblePreviewImg] = useState(false);

  const [upload] = useUploadImageMutation();
  const [ocr] = useOcrKtpMutation();

  const resetState = () => {
    setDataImg(null);
    setPreviewImage(null);
    setErrorMessage(null);
    setVisibleErrorMsg(false);
    setVisiblePreviewImg(false);
  };

  const onDismissPreviewImg = () => {
    setPreviewImage(null);
    setVisiblePreviewImg(false);
    setDataImg(null);
  };

  const fnCloseErrorMsg = () => {
    setVisibleErrorMsg(false);
    setErrorMessage(null);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    fnCloseErrorMsg();
    onDismissPreviewImg();

    const file = event.target.files[0];

    if (file === undefined) {
      setDataImg(null);
      setErrorMessage("Anda belum menambahkan foto.");
    }

    const allowedTypes = ["image/jpeg", "image/png"];
    if (!allowedTypes.includes(file.type)) {
      setVisibleErrorMsg(true);
      return setErrorMessage(`Hanya file JPG dan PNG yang diperbolehkan.`);
    }

    const maxSizeMB = 1;
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    if (file.size > maxSizeBytes) {
      setVisibleErrorMsg(true);
      return setErrorMessage(`Ukuran file melebihi batas ${maxSizeMB}MB.`);
    }

    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      setVisiblePreviewImg(true);
    };
    reader.readAsDataURL(file);
    setDataImg(file);

    event.target.value = null;
    fnUploadFile(file);
  };

  const fnUploadFile = async (dataImg) => {
    if (dataImg === null) {
      setErrorMessage("Anda belum menambahkan foto.");
      setVisibleErrorMsg(true);
      setPreviewImage(null);
    }

    try {
      const data = await dispatch(
        uploadImage({ img: dataImg, fileType: type })
      );
      if (data?.error) {
        setErrorMessage(data?.payload);
        setVisibleErrorMsg(true);
        setPreviewImage(null);
      } else if (type === "KTP" && typeof data?.payload === "object") {
        dispatch(setUrlKtp(data?.payload?.url));
        const res = await ocr({
          userId: dataUser?.id,
          image: data?.payload?.url,
        }).unwrap();
        if (res?.data) {
          dispatch(setPayloadOcr(res?.data));
        }
      } else if (type === "SELFIE" && typeof data?.payload === "object") {
        dispatch(setUrlSelfie(data?.payload?.url));
      }
    } catch (error) {
      setErrorMessage(error?.data?.meta?.message);
      setVisibleErrorMsg(true);
      setPreviewImage(null);
      console.log(error);
    }
  };

  return (
    <Card {...args}>
      {/* Error message */}
      {errorMessage !== null && (
        <Alert
          isOpen={visibleErrorMsg}
          toggle={fnCloseErrorMsg}
          color="danger"
          className="d-flex align-items-center gap-2 my-4"
        >
          <img src={ICXAlert} alt="alert icon" width={18} height={18} />
          <span>{errorMessage}</span>
        </Alert>
      )}
      {/* Image previewImage */}
      {previewImage !== null ? (
        <Alert
          className="p-3 my-4 d-flex justify-content-center align-items-center"
          isOpen={visiblePreviewImg}
          toggle={onDismissPreviewImg}
          style={{ backgroundColor: "transparent" }}
          color="secondary"
        >
          <img
            src={previewImage}
            alt="Preview"
            style={{ maxWidth: "20rem", maxHeight: "20rem" }}
          />
        </Alert>
      ) : (
        <Card
          className="p-3 d-flex justify-content-center align-items-center"
          onClick={() => {
            handleUploadClick();
          }}
          style={{ cursor: "pointer" }}
        >
          <img src={ICUpload} alt="icon" width={50} height={50} />
          <span className="text-primary fw-bolder">Klik untuk upload</span>
          <span className="text-sm">{`JPG atau PNG`}</span>
        </Card>
      )}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".jpg, .png"
      />
    </Card>
  );
};

export default CardUploadImage;
