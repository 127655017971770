import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "./loginAction";

export const initialState = {
  loading: false,
  error: null,
  token: null,
  isError: false,
  dataUser: {},
  phoneNumber: "",
};

const loginSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      Object.assign(state, initialState);
    },
    setPhoneNumber: (state, { payload }) => {
      state.phoneNumber = payload;
    },
    setDataUser: (state, { payload }) => {
      state.dataUser = payload;
    },
    clearError: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isError = false;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.isError = false;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.isError = true;
      });
  },
});

export const { logout, setPhoneNumber, setDataUser, clearError } =
  loginSlice.actions;
export default loginSlice.reducer;
