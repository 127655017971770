import { createAppSlice } from "app/createAppSlice";
import { getFarmersDetail } from "./dashboardAction";

const initialState = {
  isOpenSidebar: false,
  tag: {
    type: "dashboard",
    size: 10,
    page: 0,
  },
  isOpenBanner: false,
  details: null,
};

export const dashboardSlice = createAppSlice({
  name: "dashboard",
  initialState,
  reducers: (create) => ({
    toggleSidebarWithdraw: create.reducer((state, { payload }) => {
      state.isOpenSidebar = !state.isOpenSidebar;
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      if (state.tag.type === payload.type) {
        state.tag.size = payload.size;
        state.tag.page = payload.page;
      } else {
        state.tag.type = payload.type;
        state.tag.size = 10;
        state.tag.page = 0;
      }
      // let existingIndex = state.tag.findIndex(
      //   (item) => item.type === payload.type,
      // )
      // if (existingIndex !== -1) {
      //   state.tag[existingIndex].size = payload.size
      // } else {
      //   state.tag.push(payload)
      // }
    }),
    toggleBanner: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner;
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getFarmersDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFarmersDetail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.details = payload;
      })
      .addCase(getFarmersDetail.rejected, (state, { payload }) => {
        state.loading = false;
      });
  },
});

export const { setFetchOption, toggleSidebarWithdraw, toggleBanner } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
