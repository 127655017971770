import IconCheck from "assets/icons/check-circle.png";
import IconDanger from "assets/icons/exclamation.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Spinner } from "reactstrap";

const AccountCheck = () => {
  const navigate = useNavigate();
  const { requirements } = useSelector((s) => s.authDeletion);

  const requirementsArray = [
    requirements.req1,
    requirements.req2,
    requirements.req3 && requirements.req6,
    requirements.req4,
    requirements.req5,
  ];

  const content = [
    {
      title: "Uang Belanja",
      desc1: "Uang Belanja kamu sudah kosong",
      desc2:
        "Pastikan nominal Uang Belanja kamu adalah Rp0. Kamu bisa menggunakannya terlebih dahulu untuk bertransaksi",
    },
    {
      title: "Uang Usaha",
      desc1: "Kamu tidak memiliki uang usaha yang tertinggal",
      desc2:
        "Pastikan nominal Uang Usaha kamu adalah Rp0 Lakukan penarikan instan atau pindahkan ke Uang Belanja dan gunakan untuk bertransaksi",
    },
    {
      title: "Transaksi Berlangsung",
      desc1: "Tidak ada transaksi berlangsung di akunmu",
      desc2: "Kamu masih memiliki transaksi yang harus diselesaikan",
    },
    {
      title: "Tagihan Talangin",
      desc1: "Tidak ada tagihan Talangin yang belum selesai di akunmu",
      desc2: "Kamu masih memiliki tagihan Talangin yang harus dilunasi",
    },
    {
      title: "Tagihan PasarMIKRO",
      desc1:
        "Tidak ada tagihan lainnya ke PasarMIKRO yang belum selesai di akunmu",
      desc2: "Kamu masih memiliki tagihan lainnya kepada PasarMIKRO",
    },
  ];

  const allRequirementsMet = requirementsArray.every((req) => req);

  return (
    <div className="d-flex justify-content-between flex-column">
      <div className="d-flex flex-column gap-3">
        <div>
          <span className="fw-semibold">Kami dapat menghapus akun kamu:</span>
        </div>
        {requirements ? (
          content.map((e, i) => {
            const requirementMet = requirementsArray[i];
            return (
              <Card key={i}>
                <CardBody className="d-flex gap-3">
                  <img
                    alt="Icon Check"
                    style={{ width: "24px", height: "24px" }}
                    src={requirementMet ? IconCheck : IconDanger}
                  />
                  <div className="d-flex flex-column">
                    <p className="fw-semibold">{e.title}</p>
                    <p>{requirementMet ? e.desc1 : e.desc2}</p>
                  </div>
                </CardBody>
              </Card>
            );
          })
        ) : (
          <div
            style={{ height: "70vh" }}
            className="d-flex align-items-center justify-content-center"
          >
            {" "}
            <Spinner />
          </div>
        )}
      </div>
      <div>
        <hr></hr>
        <div className="d-flex flex-column gap-3">
          <Button
            onClick={() => navigate("/penghapusan/alasan")}
            disabled={!allRequirementsMet}
            color="oranges"
            block
          >
            Lanjut
          </Button>
          <Button
            onClick={() => navigate("/penghapusan")}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountCheck;
