import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";

const Deletion = () => {
  const navigate = useNavigate();
  const { requirements } = useSelector((s) => s.authDeletion);

  useEffect(() => {
    if (!requirements) {
      navigate("/penghapusan");
    }
  }, [requirements]);

  return (
    <Container className="custom-container p-3" style={{ minHeight: "100vh" }}>
      <Outlet />
    </Container>
  );
};

export default Deletion;
