import React from "react";
import pmgLogo from "assets/images/pmg-logo.png";

const LandingPage = () => {
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <img className="img-fluid" src={pmgLogo} alt="PasarMIKRO" />
    </div>
  );
};

export default LandingPage;
