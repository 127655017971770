import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormGroup, Modal, ModalBody } from "reactstrap";
import { toggleModal } from "./formSlice";
import {
  useChangeProfileMutation,
  useRegisterFarmerMutation,
} from "./formApiSlice";
import { useNavigate } from "react-router-dom";
import { toggleBanner } from "../dashboard/dashboardSlice";
import { AlertCircle } from "react-feather";
import {
  toggleBannerDetail,
  toggleModalChangeProfile,
} from "../detail/detailSlice";
import { useFetchFarmersDetailQuery } from "../dashboard/dashboardApiSlice";

const ModalConfirmation = ({ row }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpenModal, payload } = useSelector((s) => s.form);
  const [register, result] = useRegisterFarmerMutation();
  const [editProfile, res] = useChangeProfileMutation();
  const { refetch } = useFetchFarmersDetailQuery();

  const [errMsg, setErrMsg] = useState("");

  const handleSubmit = async () => {
    try {
      const obj = {
        ...payload,
        province: payload?.province?.value,
        city: payload?.city?.value,
        district: payload?.district?.value,
        subDistrict: payload?.subDistrict?.value,
        postalCode: payload?.postalCode?.value,
      };

      const res = row
        ? await editProfile({ payload: obj, id: row?.userId })
        : await register(obj);
      if (res?.error) {
        setErrMsg(res?.error?.data?.meta?.message);
      } else if (!row) {
        navigate("/farmers");
        dispatch(toggleBanner());
      } else if (row) {
        refetch({ id: row?.userId });
        dispatch(toggleModal());
        dispatch(toggleModalChangeProfile());
        dispatch(toggleBannerDetail());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpenModal}
      toggle={() => {
        dispatch(toggleModal());
        setErrMsg("");
      }}
    >
      <ModalBody>
        <h6>Konfirmasi {row ? "Pengubahan" : "Pendaftaran"}</h6>
        <p className="text-muted">
          Pastikan semua data yang dimasukkan sudah benar dan sesuai.
        </p>
        {errMsg && (
          <FormGroup>
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </FormGroup>
        )}
        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              dispatch(toggleModal());
              setErrMsg("");
            }}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button onClick={handleSubmit} color="primary" block>
            {row ? "Ubah" : "Daftarkan"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalConfirmation;
