import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "reactstrap";

const TncDeletion = () => {
  const navigate = useNavigate();
  const [isCheck, setIsCheck] = useState(false);

  return (
    <div className="d-flex justify-content-between flex-column">
      <div className="d-flex flex-column gap-5">
        <div>
          <span className="fw-semibold">
            Setelah dihapus, kamu akan kehilangan akses pada informasi berikut:
          </span>
        </div>
        <div className="d-flex gap-2">
          <div className="fw-semibold">1. </div>
          <div className="d-flex flex-column">
            <span className="fw-semibold">Profil PasarMIKRO</span>
            <span className="text-muted">
              Seluruh informasi pribadi (nama, KTP, nomor rekening, data selama
              verifikasi) dan informasi toko
            </span>
          </div>
        </div>
        <div className="d-flex gap-2">
          <div className="fw-semibold">2. </div>
          <div className="d-flex flex-column">
            <span className="fw-semibold">Riwayat Transaksi</span>
            <span className="text-muted">
              Riwayat penawaran masuk & penawaran keluar, riwayat pembelian
              serta penjualan komoditas, metode pembayaran, dst.
            </span>
          </div>
        </div>
        <div className="d-flex gap-2">
          <div className="fw-semibold">2. </div>
          <div className="d-flex flex-column">
            <span className="fw-semibold">Talangin</span>
            <span className="text-muted">
              Limit Talangin kamu akan dinon-aktifkan setelah penghapusan akun
            </span>
          </div>
        </div>
      </div>
      <div>
        <div>
          <hr></hr>
          <p className="text-muted">
            PasarMIKRO tidak bertanggung jawab atas hilangnya informasi, data,
            atau uang setelah akun dihapus.
          </p>
          <div
            onClick={() => setIsCheck(true)}
            className="rounded border d-flex gap-2 align-items-center p-3"
          >
            <Input
              type="checkbox"
              onChange={() => setIsCheck(!isCheck)}
              checked={isCheck}
            />
            <span className="fw-semibold">
              Saya memahami dan setuju untuk menghapus akun ini secara permanen.
            </span>
          </div>
        </div>
        <hr></hr>
        <div className="d-flex flex-column gap-3">
          <Button
            disabled={!isCheck}
            onClick={() => navigate("/penghapusan/cek-akun")}
            color="oranges"
            className="text-white"
            block
          >
            Ya, Hapus Akun
          </Button>
          <Button
            onClick={() => navigate("/penghapusan")}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TncDeletion;
