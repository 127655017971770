import PmgLogo from "assets/images/pmg-logo.png";
import { useState } from "react";
import { AlignLeft } from "react-feather";
import { Link, NavLink as NavLinkDom } from "react-router-dom";
import {
  Collapse,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";
import UserDropdown from "./UserDropdown";

const NavbarDashboard = (args) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar {...args}>
        <NavbarBrand tag={Link} to="/farmers">
          <img
            alt="PasarMIKRO logo"
            src={PmgLogo}
            style={{ width: "100%", maxWidth: "120px", height: "auto" }}
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} children={<AlignLeft />} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLinkDom
                to="/farmers"
                aria-current="page"
                className={({ isActive }) => {
                  if (isActive) {
                    return `nav-link active-link`;
                  } else {
                    return `nav-link`;
                  }
                }}
              >
                Dashboard
              </NavLinkDom>
            </NavItem>
          </Nav>

          <UserDropdown />
        </Collapse>
      </Navbar>
    </>
  );
};

export default NavbarDashboard;
