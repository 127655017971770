import LogoPasmik from "assets/images/pmg-logo.png";
import { useEffect, useState } from "react";
import { AlertCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { loginUser } from "../login/loginAction";
import { clearError, setDataUser } from "../login/loginSlice";

const PinFarmers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pin, setPin] = useState([]);
  const [active, setActive] = useState("form-control-0");
  const [isAction, setIsAction] = useState(false);
  let { phoneNumber, error, dataUser } = useSelector((s) => s.auth);

  const handleSubmit = async () => {
    try {
      setIsAction(true);
      const resp = await dispatch(
        loginUser({ phoneNumber, password: pin.join("") })
      );
      if (resp?.error) {
        throw resp;
      } else {
        dispatch(setDataUser(resp?.payload?.data));
        navigate("/farmers");
      }
    } catch (error) {
    } finally {
      setIsAction(false);
    }
  };

  const nextInput = (id) => {
    const input = document.getElementById(id);
    if (input) input.focus();
  };

  useEffect(() => {
    if (pin.length === 6 && pin.every((pin) => pin !== "" && !isAction)) {
      handleSubmit();
    }
  }, [pin]);

  useEffect(() => {
    if (dataUser?.api_token) {
      navigate("/farmers");
    } else if (!dataUser?.api_token && !phoneNumber) {
      navigate("/farmers/login");
    }
  }, [dataUser]);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Card style={{ maxWidth: "600px", width: "100%" }}>
          <CardBody>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                style={{ width: "100%", maxWidth: "177px", height: "auto" }}
                src={LogoPasmik}
                alt="PasarMIKRO logo"
              />
            </div>
            <CardTitle className="d-flex justify-content-center mb-4" tag="h4">
              Masukkan PIN anda
            </CardTitle>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="d-flex gap-2">
                {[...Array(6)].map((_, i) => (
                  <div key={i}>
                    <div
                      className="position-absolute top-0 start-0 w-100 h-100"
                      style={{ zIndex: 2, backgroundColor: "transparent" }}
                      onClick={() => nextInput(active)}
                    ></div>
                    <Input
                      style={{ zIndex: 1 }}
                      autoFocus={i === 0}
                      className="w-100 h-100 fs-2 border border-primary text-center text-primary"
                      id={`form-control-${i}`}
                      key={i}
                      maxLength={1}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onKeyDown={(e) => {
                        dispatch(clearError());
                        const value = e.target.value;
                        if (
                          !e.code.includes("Digit") &&
                          e.code !== "Backspace"
                        ) {
                          e.preventDefault();
                        } else if (value.match(/^[0-9]$/)) {
                          if (e.code.includes("Digit")) {
                            setActive(`form-control-${i + 1}`);
                            nextInput(`form-control-${i + 1}`);
                          }
                          setPin((prev) => {
                            const newPin = [...prev];
                            newPin[i] = value;
                            return newPin;
                          });
                        } else {
                          if (e.code === "Backspace") {
                            setActive(`form-control-${i - 1}`);
                            nextInput(`form-control-${i - 1}`);
                            setPin((prevPin) => {
                              return prevPin.filter((_, index) => index !== i);
                            });
                          } else {
                          }
                        }
                      }}
                      onChange={(e) => {
                        dispatch(clearError());
                        const value = e.target.value;
                        if (value.match(/^[0-9]$/)) {
                          setPin((prev) => {
                            const newPin = [...prev];
                            newPin[i] = value;
                            return newPin;
                          });
                          if (e.target.id !== "form-control-5") {
                            setActive(`form-control-${i + 1}`);
                            nextInput(`form-control-${i + 1}`);
                          }
                        } else {
                          if (e.target.id !== "form-control-0") {
                            setActive(`form-control-${i - 1}`);
                            nextInput(`form-control-${i - 1}`);
                            setPin((prevPin) => {
                              return prevPin.filter((_, index) => index !== i);
                            });
                          } else {
                            return null;
                          }
                        }
                      }}
                      autoComplete="off"
                    />
                  </div>
                ))}
              </FormGroup>
            </Form>
            {error && (
              <FormGroup>
                <small className="text-danger d-flex align-items-center gap-2">
                  <AlertCircle size={15} /> {error}
                </small>
              </FormGroup>
            )}
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default PinFarmers;
