import { createBrowserRouter, redirect } from "react-router-dom";
import LoginDeletion from "views/deletion/login";
import Pin from "views/deletion/pin";
import Reason from "views/deletion/reason";
import SuccessDelete from "views/deletion/success-delete";
import FarmersWeb from "views/farmers-web";
import Dashboard from "views/farmers-web/dashboard";
import DetailUser from "views/farmers-web/detail";
import FormRegis from "views/farmers-web/forms";
import Login from "views/farmers-web/login";
import LandingPage from "views/landing-page";
import App from "../App";
import Deletion from "../views/deletion";
import AccountCheck from "../views/deletion/check-account";
import TncDeletion from "../views/deletion/tnc";
import PinFarmers from "views/farmers-web/pin";

export const routes = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        element: <Deletion />,
        children: [
          {
            path: "/penghapusan/syarat-dan-ketentuan",
            element: <TncDeletion />,
          },
          {
            path: "/penghapusan/cek-akun",
            element: <AccountCheck />,
          },
          {
            path: "/penghapusan/alasan",
            element: <Reason />,
          },
          {
            path: "/penghapusan/sukses",
            element: <SuccessDelete />,
          },
        ],
      },
      {
        element: <FarmersWeb />,
        children: [
          {
            path: "/farmers",
            element: <Dashboard />,
          },
          {
            path: "/farmers/registrasi",
            element: <FormRegis />,
          },
          {
            path: "/farmers/:id",
            element: <DetailUser />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <LandingPage />,
    loader: () => redirect("https://www.pasarmikro.id"),
  },
  {
    path: "/penghapusan",
    element: <LoginDeletion />,
  },
  {
    path: "/penghapusan/pin",
    element: <Pin />,
  },
  {
    path: "/farmers/login",
    element: <Login />,
  },
  {
    path: "/farmers/pin",
    element: <PinFarmers />,
  },
  {
    path: "*",
    element: <LandingPage />,
  },
]);
