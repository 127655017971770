import React from "react";
import SuccessDeletePic from "assets/images/Illustration-delete-success.png";
import { Button } from "reactstrap";

const SuccessDelete = () => {
  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex justify-content-center mt-5 align-items-center">
        <img
          style={{ height: "381px", width: "361px" }}
          className="img-fluid"
          alt="Sukses"
          src={SuccessDeletePic}
        />
      </div>
      <div>
        <Button block color="primary">
          Selesai
        </Button>
      </div>
    </div>
  );
};

export default SuccessDelete;
