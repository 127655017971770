import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CardUploadImage from "./CardUploadImage";
import ModalConfirmation from "./ModalConfirmation";
import { useFetchBanksQuery, usePreCheckFarmerMutation } from "./formApiSlice";
import { setPayloadSlice, toggleModal } from "./formSlice";
import {
  getListCity,
  getListDistrict,
  getListPostalCode,
  getListProvinces,
  getListSubdistrict,
} from "./formsAction";
import { AlertCircle } from "react-feather";
import { toggleModalChangeProfile } from "../detail/detailSlice";
import ReactDatePicker from "react-datepicker";

const FormRegis = ({ row }) => {
  const dispatch = useDispatch();
  const {
    provinces,
    cities,
    districts,
    subDistricts,
    postalCodes,
    payloadOcr,
    urlSelfie,
    urlKtp,
  } = useSelector((s) => s.form);
  const { data: banks } = useFetchBanksQuery();
  const [precheck, result] = usePreCheckFarmerMutation();

  const [disableCity, setDisableCity] = useState(false);
  const [disableDistrict, setDisableDistrict] = useState(false);
  const [disableSubDistrict, setDisableSubDistrict] = useState(false);
  const [disablePostalCode, setDisablePostalCode] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [payload, setPayload] = useState({});
  const [isClick, setIsClick] = useState(false);

  const handleDob = (date) => {
    const parts = date?.split("-");
    const formattedDate = parts?.reverse().join("-");
    return formattedDate;
  };

  const handleHasAnswered = () => {
    const fieldRequiredRegister = [
      "identity",
      "password",
      "imgKTP",
      "imgSelfie",
      "fullName",
      "dob",
      "sex",
      "noKTP",
      "province",
      "city",
      "district",
      "subDistrict",
      "postalCode",
      "address",
      "bankCode",
      "bankName",
      "bankAccountNumber",
      "bankAccountName",
    ];

    const fieldRequiredEdit = [
      "fullName",
      "dob",
      "sex",
      "noKTP",
      "province",
      "city",
      "district",
      "subDistrict",
      "postalCode",
      "address",
    ];

    const fieldRequired = !row ? fieldRequiredRegister : fieldRequiredEdit;

    const data = fieldRequired.map((e) => {
      return payload.hasOwnProperty(e) && payload[e];
    });
    return data.every((e) => e);
  };

  useEffect(() => {
    if (provinces.length < 1) {
      dispatch(getListProvinces());
    }
    if (postalCodes.length > 0) {
      setPayload((prev) => ({
        ...prev,
        postalCode: postalCodes[0],
      }));
    }
    if (urlSelfie) {
      setPayload((prev) => ({
        ...prev,
        imgSelfie: urlSelfie,
      }));
    }
    if (urlKtp) {
      setPayload((prev) => ({
        ...prev,
        imgKTP: urlKtp,
      }));
    }
  }, [provinces, postalCodes, urlSelfie, urlKtp]);

  useEffect(() => {
    if (payloadOcr) {
      setPayload((prev) => ({
        ...prev,
        dob: payloadOcr?.dob,
        city: payloadOcr?.city,
        fullName: payloadOcr?.fullName,
        sex:
          payloadOcr?.gender === "LAKI-LAKI"
            ? "1"
            : payloadOcr?.gender === "PEREMPUAN"
              ? "0"
              : "",
        noKTP: payloadOcr?.nik,
        province: {
          value: payloadOcr?.regionInfo?.province?.nama,
          label: payloadOcr?.regionInfo?.province?.nama,
          id: payloadOcr?.regionInfo?.province?.id,
        },
        city: {
          value: payloadOcr?.regionInfo?.city?.nama,
          label: payloadOcr?.regionInfo?.city?.nama,
          id: payloadOcr?.regionInfo?.city?.id,
        },
        district: {
          value: payloadOcr?.regionInfo?.district?.nama,
          label: payloadOcr?.regionInfo?.district?.nama,
          id: payloadOcr?.regionInfo?.district?.id,
        },
        subDistrict: {
          value: payloadOcr?.regionInfo?.subDistrict?.nama,
          label: payloadOcr?.regionInfo?.subDistrict?.nama,
          id: payloadOcr?.regionInfo?.subDistrict?.id,
        },
        address: payloadOcr?.address,
      }));
      dispatch(getListCity({ id: payloadOcr?.regionInfo?.province?.id }));
      dispatch(getListDistrict({ id: payloadOcr?.regionInfo?.city?.id }));
      dispatch(
        getListSubdistrict({ id: payloadOcr?.regionInfo?.district?.id })
      );
      dispatch(
        getListPostalCode({ id: payloadOcr?.regionInfo?.subDistrict?.id })
      );
    }
  }, [payloadOcr]);

  useEffect(() => {
    if (result) {
      if (result?.error) {
        setErrMsg(result?.error?.data?.meta?.message);
      }
    }
    if (row) {
      setPayload((prev) => ({
        ...prev,
        dob: row?.dob,
        city: row?.city,
        fullName: row?.fullName,
        sex: row?.sex,
        noKTP: row?.noKTP,
        email: row?.email,
        province: {
          value: row?.province,
          label: row?.province,
        },
        city: {
          value: row?.city,
          label: row?.city,
        },
        district: {
          value: row?.district,
          label: row?.district,
        },
        subDistrict: {
          value: row?.subDistrict,
          label: row?.subDistrict,
        },
        postalCode: {
          value: row?.postalCode,
          label: row?.postalCode,
        },
        address: row?.address,
      }));
    }
  }, [result, row]);

  return (
    <Container className="custom-container d-flex flex-column gap-3 py-3">
      <ModalConfirmation row={row} />
      {!row ? (
        <>
          {/* Data Akun */}
          <Card>
            <CardBody className="d-flex flex-column gap-2">
              <h5 className="fw-semibold">Daftarkan Pengguna</h5>
              <div className="mt-3">
                <h5>Data akun</h5>
                <p className="text-muted">
                  Digunakan untuk login ke akun PasarMIKRO
                </p>
              </div>
              <FormGroup>
                <Label className="fw-semibold">Nomor telepon</Label>
                <Input
                  placeholder="Masukkan nomor telepon"
                  id="identity"
                  name="identity"
                  value={payload?.identity}
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value.replace(/[^0-9]+/g, ""),
                    }));
                    setErrMsg("");
                  }}
                  autoComplete="off"
                  invalid={!payload?.identity && isClick}
                />
              </FormGroup>
              <FormGroup>
                <Label className="fw-semibold">PIN</Label>
                <Input
                  placeholder="Masukkan 6 digit PIN"
                  type="password"
                  name="password"
                  id="password"
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value.replace(/[^0-9]+/g, ""),
                    }));
                  }}
                  value={payload?.password}
                  autoComplete="off"
                  maxLength={6}
                  onFocus={() => {
                    if (payload?.identity) {
                      precheck({ identity: payload?.identity });
                    }
                  }}
                  invalid={!payload?.password && isClick}
                />
              </FormGroup>
              {errMsg && (
                <FormGroup>
                  <small className="text-danger">
                    <AlertCircle size={15} /> {errMsg}
                  </small>
                </FormGroup>
              )}
            </CardBody>
          </Card>

          {/* Data Foto */}
          <Card>
            <CardBody className="d-flex flex-column gap-2">
              <h5 className="fw-semibold">Identitas</h5>
              <FormGroup>
                <Label className="fw-semibold">Identitas</Label>
                <CardUploadImage
                  type="KTP"
                  className={`${!payload?.imgKTP && isClick ? "border-danger" : ""}`}
                />
              </FormGroup>
              <FormGroup>
                <Label className="fw-semibold">Foto Selfie</Label>
                <CardUploadImage
                  type="SELFIE"
                  className={`${!payload?.imgSelfie && isClick ? "border-danger" : ""}`}
                />
              </FormGroup>
            </CardBody>
          </Card>
        </>
      ) : (
        <></>
      )}

      {/* Data Diri */}
      <Card>
        <CardBody className="d-flex flex-column gap-2">
          {!row ? (
            <>
              <div>
                <h5>Lengkapi data diri</h5>
                <p className="text-muted">
                  Beberapa data terisi secara otomatis dari foto KTP yang
                  di-upload. Periksa kembali sebelum lanjut.
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
          <FormGroup>
            <Label className="fw-semibold">Nama</Label>
            <Input
              placeholder="-"
              autoComplete="off"
              name="fullName"
              id="fullName"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
              }}
              value={payload?.fullName}
              invalid={!payload?.fullName && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Label className="fw-semibold">Tanggal lahir</Label>
            <Input
              placeholder="-"
              autoComplete="off"
              type="date"
              name="dob"
              id="dob"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: handleDob(e.target.value),
                }));
              }}
              value={handleDob(payload.dob)}
              invalid={!payload?.dob && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Label className="fw-semibold">Jenis Kelamin</Label>
            <Row>
              <Col>
                <div
                  onClick={() => {
                    setPayload((prev) => ({
                      ...prev,
                      sex: "1",
                    }));
                  }}
                  className={`d-flex justify-content-between align-items-center p-2 rounded border
                      ${!payload?.sex && isClick ? "border-danger" : ""}`}
                >
                  Laki-laki
                  <Input
                    name="sex"
                    id="Laki-laki"
                    onChange={(e) => {
                      setPayload((prev) => ({
                        ...prev,
                        [e.target.name]: "1",
                      }));
                    }}
                    value={payload?.sex}
                    checked={payload?.sex === "1"}
                    type="radio"
                    invalid={!payload?.sex && isClick}
                  />
                </div>
              </Col>
              <Col>
                <div
                  onClick={() => {
                    setPayload((prev) => ({
                      ...prev,
                      sex: "0",
                    }));
                  }}
                  className={`d-flex justify-content-between align-items-center p-2 rounded border
                  ${!payload?.sex && isClick ? "border-danger" : ""}`}
                >
                  Perempuan
                  <Input
                    name="sex"
                    id="Perempuan"
                    onChange={(e) => {
                      setPayload((prev) => ({
                        ...prev,
                        [e.target.name]: "0",
                      }));
                    }}
                    value={payload?.sex}
                    checked={payload?.sex === "0"}
                    type="radio"
                    invalid={!payload?.sex && isClick}
                  />
                </div>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label className="fw-semibold">Email (opsional)</Label>
            <Input
              placeholder="-"
              autoComplete="off"
              name="email"
              id="email"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
              }}
              value={payload?.email}
              type="email"
            />
          </FormGroup>
          <FormGroup>
            <Label className="fw-semibold">NIK</Label>
            <Input
              name="noKTP"
              id="noKTP"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value.replace(/[^0-9]+/g, ""),
                }));
              }}
              value={payload?.noKTP}
              placeholder="-"
              autoComplete="off"
              invalid={!payload?.noKTP && isClick}
            />
          </FormGroup>
          <FormGroup>
            <Label className="fw-semibold">Provinsi</Label>
            <Select
              options={provinces}
              name="province"
              id="province"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  province: e,
                  city: null,
                  district: null,
                  subDistrict: null,
                  postalCode: null,
                }));
                dispatch(getListCity({ id: e.id }));
                setDisableDistrict(true);
                setDisableSubDistrict(true);
                setDisablePostalCode(true);
              }}
              value={payload?.province}
              placeholder="Pilih provinsi"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor:
                    !payload?.province && isClick ? "red" : "#dee2e6",
                }),
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label className="fw-semibold">Kota</Label>
            <Select
              options={cities}
              isDisabled={disableCity}
              name="city"
              id="city"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  city: e,
                  district: null,
                  subDistrict: null,
                  postalCode: null,
                }));
                dispatch(getListDistrict({ id: e.id }));
                setDisableDistrict(false);
                setDisableSubDistrict(true);
                setDisablePostalCode(true);
              }}
              value={payload?.city}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: !payload?.city && isClick ? "red" : "#dee2e6",
                }),
              }}
              placeholder="Pilih kota"
            />
          </FormGroup>
          <FormGroup>
            <Label className="fw-semibold">Kecamatan</Label>
            <Select
              options={districts}
              isDisabled={disableDistrict}
              name="district"
              id="district"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  district: e,
                  subDistrict: null,
                  postalCode: null,
                }));
                dispatch(getListSubdistrict({ id: e.id }));
                setDisableSubDistrict(false);
              }}
              value={payload?.district}
              placeholder="Pilih kecamatan"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor:
                    !payload?.district && isClick ? "red" : "#dee2e6",
                }),
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label className="fw-semibold">Kelurahan</Label>
            <Select
              options={subDistricts}
              name="subDistrict"
              id="subDistrict"
              isDisabled={disableSubDistrict}
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  subDistrict: e,
                  postalCode: null,
                }));
                dispatch(getListPostalCode({ id: e.id }));
                setDisablePostalCode(false);
              }}
              value={payload?.subDistrict}
              placeholder="Pilih kelurahan"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor:
                    !payload?.subDistrict && isClick ? "red" : "#dee2e6",
                }),
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label className="fw-semibold">Kode pos</Label>
            <Select
              options={postalCodes}
              name="postalCode"
              id="postalCode"
              isDisabled={disablePostalCode}
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  postalCode: e,
                }));
              }}
              value={payload?.postalCode}
              placeholder="Pilih kode pos"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor:
                    !payload?.postalCode && isClick ? "red" : "#dee2e6",
                }),
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label className="fw-semibold">Alamat</Label>
            <Input
              name="address"
              id="address"
              placeholder="-"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
              }}
              value={payload?.address}
              autoComplete="off"
              invalid={!payload?.address && isClick}
            />
          </FormGroup>
        </CardBody>
      </Card>

      {!row ? (
        <>
          {/* Data Bank */}
          <Card>
            <CardBody className="d-flex flex-column gap-2">
              <div>
                <h5>Data akun</h5>
                <p className="text-muted">
                  Digunakan untuk menerima pembayaran saat melakukan penjualan
                </p>
              </div>
              <FormGroup>
                <Label className="fw-semibold">Nomor rekening</Label>
                <Input
                  name="bankAccountNumber"
                  id="bankAccountNumber"
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  value={payload?.bankAccountNumber}
                  placeholder="Masukkan nomor rekening"
                  autoComplete="off"
                  invalid={!payload?.bankAccountNumber && isClick}
                />
              </FormGroup>
              <FormGroup>
                <Label className="fw-semibold">Bank</Label>
                <Select
                  options={banks}
                  name="bankName"
                  id="bankName"
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      bankName: e.value,
                      bankCode: e.code,
                    }));
                  }}
                  value={{ value: payload?.bankName, label: payload?.bankName }}
                  placeholder="Pilih bank"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        (!payload.hasOwnProperty("bankName") ||
                          !payload?.bankName) &&
                        isClick
                          ? "red"
                          : "#dee2e6",
                    }),
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label className="fw-semibold">Nama pemilik rekening</Label>
                <Input
                  name="bankAccountName"
                  id="bankAccountName"
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  value={payload?.bankAccountName}
                  placeholder="Masukkan nama pemilik rekening"
                  autoComplete="off"
                  invalid={
                    (!payload.hasOwnProperty("bankAccountName") ||
                      !payload?.bankAccountName) &&
                    isClick
                  }
                />
              </FormGroup>
            </CardBody>
          </Card>

          <span className="fw-semibold">
            Dengan mendaftarkan pengguna, saya menjamin kebenaran data yang
            dimasukkan dan bertanggung jawab atas ketidaksesuaian data.
          </span>
        </>
      ) : (
        <></>
      )}

      <div className="d-flex gap-3">
        {row ? (
          <Button
            onClick={() => {
              dispatch(toggleModalChangeProfile());
            }}
            outline
            color="primary"
            block
          >
            Batal
          </Button>
        ) : (
          <></>
        )}
        <Button
          onClick={() => {
            const res = handleHasAnswered();
            setIsClick(true);
            if (res) {
              setIsClick(false);
              dispatch(toggleModal());
              dispatch(setPayloadSlice(payload));
            }
          }}
          color="primary"
          block
        >
          {!row ? "Daftarkan User" : "Simpan"}
        </Button>
      </div>
    </Container>
  );
};

export default FormRegis;
