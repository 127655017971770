import { createAppSlice } from "app/createAppSlice";
import { sendOtp, sendVerifyOtp } from "./reasonAction";

const initialState = {
  isOpenModal: false,
  reason: "",
  error: "",
  loading: false,
};

export const reasonSlice = createAppSlice({
  name: "deletion/reason",
  initialState,
  reducers: (create) => ({
    toggleModal: create.reducer((state, { payload }) => {
      state.isOpenModal = !state.isOpenModal;
      state.error = "";
    }),
    setReason: create.reducer((state, { payload }) => {
      state.reason = payload;
    }),
    clearError: create.reducer((state, { payload }) => {
      state.error = payload;
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(sendOtp.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(sendOtp.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(sendOtp.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(sendVerifyOtp.rejected, (state, { payload }) => {
      state.error = payload;
    });
  },
});

export const { toggleModal, setReason, clearError } = reasonSlice.actions;

export default reasonSlice.reducer;
